// Write your custom style. Feel free to split your code to several files

.skiptomaincontent-link {
  position: absolute;
  left: -9999px;
  z-index: 99999;
  padding: 1em;
  background-color: $white;
  color: $white;
  opacity: 0;

}

.skiptomaincontent-link:focus {
  left: 50%;
  transform: translateX(-50%);
  opacity: 1;
  color: $color-primary;
}

.brand-highlight {
  color: $color-primary;
}

small {

  &.disclaimer {
    font-weight: 400;
    text-transform: none;
    letter-spacing: unset;
    word-spacing: unset;
  }
}

img {

  &.logo-dark, &.logo-light{
    max-height: 3.125rem;
    max-width: 8.75rem;
  }
}

p {

  &.highlight {
    font-size: $h6-font-size;
    font-weight: $h6-font-weight;
    color: $color-text-darker;
  }
}

ol,ul {

  &.list-no-style-type{
    list-style-type: unset;
  }
}

footer {

  img {

    &.logo-dark, &.logo-light{
      filter: grayscale(1);
    }
  }
}
